import { find, maxBy, pickBy, inRange } from 'lodash';
import { SCORE } from 'common/constants';
import { CardFacadeBase } from 'utils/facades/card-facade-base';
import { location } from 'utils/url-helper';
import type { CardFlowEvent, StorySettingsType } from 'types/story';
import { type TotalScoreType, type TotalCharacterPointsType, StoryHistory } from 'client/utils';
import { StoryFacade } from './story-facade';

export enum CountType {
	score = 'score', // Total score calculated relative to the maximum
	character = 'character', // Total number of points scored by an individual character
	ab = 'ab', // A/B navigation
	urlParam = 'urlParam', // URL parameter
}

type GetNextEventByScore = (score: TotalScoreType) => CardFlowEvent | null | undefined;
type GetNextEventByResultPoints = (score: TotalCharacterPointsType) => CardFlowEvent | null | undefined;
type GetNextEventByRate = (story: StoryFacade) => CardFlowEvent | null | undefined;
type GetNextEventByUrlParam = () => CardFlowEvent | null | undefined;

class NavigationFacade extends CardFacadeBase {
	private getNextEventByScore: GetNextEventByScore = ({ scoreRelative: totalScore }) => {
		if (!this.data) {
			return null;
		}

		return find(this.data.events, ({ score }) => {
			if (!score) {
				return false;
			}
			const min = score[0] || SCORE.RANGE.MIN;
			const max = Number.isInteger(score[1]) ? score[1] + 1 : Infinity;
			return inRange(totalScore, min, max);
		});
	};

	private getNextEventByCharacterPoints: GetNextEventByResultPoints = totalCharacterPoints => {
		const { characters } = this;
		const totalCardCharacterPoints = pickBy(totalCharacterPoints, (value, key) => key in characters);
		const [characterId] = maxBy(Object.entries(totalCardCharacterPoints), ([, value]) => value) ?? [];
		return characterId === undefined ? null : find(this.data.events, o => o._id === characterId);
	};

	private getNextEventByUrlParam: GetNextEventByUrlParam = () => {
		if (!this.data || !this.data.settings.urlNavigationParam) {
			return null;
		}

		const urlParam = this.data.settings.urlNavigationParam;
		const currentUrlParams = new URL(window.location.href).searchParams;
		const targetValue = currentUrlParams.get(urlParam);

		return this.data.events.find(e => e.name === targetValue) ?? null;
	};

	private getNextEventByRate: GetNextEventByRate = storyFacade => {
		if (!this.data) {
			return null;
		}

		const { isPreview } = location.client;
		const storyHistory = !isPreview ? new StoryHistory({ storyId: storyFacade.storyId }) : null;
		const savedRate = storyHistory?.getCardABRate(this.data._id);
		const rate = savedRate ?? Math.round(Math.random() * 100);

		if (!isPreview && savedRate === undefined && storyHistory) {
			storyHistory.addABRate({ cardId: this.data._id, rate });
		}

		const sortedEvents = this.data.events.filter(e => e.rate).sort((a, b) => a.rate! - b.rate!); // ascending order
		let accumulatedRate = 0;
		let result: CardFlowEvent | null = null;

		sortedEvents.forEach(event => {
			accumulatedRate += event.rate!;
			if (!result && rate <= accumulatedRate) {
				result = event;
			}
		});

		return result;
	};

	get nextEvent() {
		return {
			[CountType.score]: this.getNextEventByScore,
			[CountType.character]: this.getNextEventByCharacterPoints,
			[CountType.ab]: this.getNextEventByRate,
			[CountType.urlParam]: this.getNextEventByUrlParam,
		};
	}

	get characters() {
		return this.data.events.reduce(
			(acc, event) => {
				acc[event._id] = { name: event.name, cardId: this.data._id };
				return acc;
			},
			{} as Exclude<StorySettingsType['characters'], undefined>
		);
	}

	getCountType = (storySettings: StorySettingsType) => {
		return storySettings.cards?.[this.data._id]?.countType ?? CountType.score;
	};
}

export { NavigationFacade };
